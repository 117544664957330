/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

:root {
  --dl-primary: #464b6a;
  --dl-accent: #34b2b5;
  --dl-red: #eb5757;
  --dl-red-2: #ff6c6c;
  --dl-green-1: #219653;
  --dl-green-2: #27ae60;
  --dl-green-3: #6fcf97;
  --dl-blue-3: #56ccf2;
  --dl-orange: #f2994a;
  --dl-yellow: #f2c94c;
  --dl-gray-2: #4f4f4f;
  --dl-gray-3: #828282;
  --dl-gray-4: #bdbdbd;
  --dl-gray-5: #e0e0e0;
  --dl-gray-6: #f2f2f2;
  --dl-purple: #bb6bd9;
  --dl-purple-tint: #d892f1;

  color: var(--dl-primary);
}

* {
  box-sizing: border-box;
}

body {
  background-color: #f0f1f5;
  color: #333;
}

input {
  background: none;
}

dl-input {
  --font-color: var(--dl-primary) !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.spinner-overlay {
  z-index: 1000;
  @apply absolute top-0 w-full flex justify-center items-center bg-black bg-opacity-50 left-0 h-full;
}

// Fix input icons not being visible on dark mode
input {
  color-scheme: light;
}

.tooltip {
  white-space: pre-line;
}

.modal-red-text {
  color: var(--dl-red) !important;
}
