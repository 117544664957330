@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Roboto&display=swap");

body {
    --dl-primary: #464b6a;
    --dl-secondary: #b7b9c5;
    --dl-accent: #34b2b5;
    --dl-warn: #e74c3c;
    --dl-success: #27ae60;

    --dl-red: #eb5757;
    --dl-orange: #f2994a;
    --dl-yellow: #f2c94c;
    --dl-green-1: #219653;
    --dl-green-2: #27ae60;
    --dl-green-3: #6fcf97;
    --dl-blue-1: #2f80ed;
    --dl-blue-2: #2d9cdb;
    --dl-blue-3: #56ccf2;
    --dl-purple-1: #9b51e0;
    --dl-purple-2: #bb6bd9;
}

.dl-primary {
    background-color: var(--dl-primary) !important;
    color: #fff !important;
}

.dl-secondary {
    background-color: var(--dl-secondary) !important;
    color: #000 !important;
}

.dl-accent {
    background-color: var(--dl-accent) !important;
    color: #000 !important;
}

.dl-warn {
    background-color: var(--dl-warn) !important;
    color: #fff !important;
}

.dl-success {
    background-color: var(--dl-success) !important;
    color: #fff !important;
}

span.dl-success {
    color: #fff !important;
}

.dl-red {
    background-color: var(--dl-red) !important;
    color: #fff !important;
}

.dl-orange {
    background-color: var(--dl-orange) !important;
    color: #000 !important;
}

.dl-yellow {
    background-color: var(--dl-yellow) !important;
    color: #000 !important;
}

.dl-green-1 {
    background-color: var(--dl-green-1) !important;
    color: #fff !important;
}
.dl-green-2 {
    background-color: var(--dl-green-2) !important;
    color: #fff !important;
}
.dl-green-3 {
    background-color: var(--dl-green-3) !important;
    color: #000 !important;
}

.dl-blue-1 {
    background-color: var(--dl-blue-1) !important;
    color: #fff !important;
}

.dl-blue-2 {
    background-color: var(--dl-blue-2) !important;
    color: #fff !important;
}

.dl-blue-3 {
    background-color: var(--dl-blue-3) !important;
    color: #000 !important;
}

.dl-purple-1 {
    background-color: var(--dl-purple-1) !important;
    color: #fff !important;
}

.dl-purple-2 {
    background-color: var(--dl-purple-2) !important;
    color: #000 !important;
}

/*
 * Remove the border when a color is set
 */
dl-default-toast.dl-primary,
dl-default-toast.dl-secondary,
dl-default-toast.dl-accent,
dl-default-toast.dl-warn,
dl-default-toast.dl-success,
dl-default-toast.dl-red,
dl-default-toast.dl-orange,
dl-default-toast.dl-yellow,
dl-default-toast.dl-green-1,
dl-default-toast.dl-green-2,
dl-default-toast.dl-green-3,
dl-default-toast.dl-blue-1,
dl-default-toast.dl-blue-2,
dl-default-toast.dl-blue-3,
dl-default-toast.dl-purple-1,
dl-default-toast.dl-purple-2 {
    border: none;
}

.dl-no-interaction {
    pointer-events: none;
}

.dl-color-red {
    color: var(--dl-red) !important;
}
